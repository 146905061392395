<!-- 代理商运单分享 -->
<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">运单号</label>
          <el-input
            placeholder="输入运单号"
            v-model="searchData.waybillno"
            clearable
          >
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">分享日期</label>
          <el-date-picker
            v-model="searchData.createitme"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="sc-item">
          <label class="sci-title">状态</label>
          <el-checkbox-group v-model="searchData.isdel">
            <el-checkbox label="0">有效</el-checkbox>
            <el-checkbox label="1">无效</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <el-button @click="handleSearch" icon="el-icon-search" type="primary"
            >查询</el-button
          >
          <el-button @click="handleReset" icon="el-icon-refresh-left"
            >重置</el-button
          >
        </div>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          header-cell-class-name="table-header"
        >
          <el-table-column
            type="index"
            :index="calcIndex"
            label="序号"
            width="50"
            fixed
          >
          </el-table-column>
          <el-table-column prop="agencyName" label="代理商"> </el-table-column>
          <el-table-column prop="code" label="分享码">
            <template slot-scope="scope">
              <div class="copy-container">
                {{ scope.row.code
                }}<i
                  class="el-icon-copy-document but-copy"
                  @click="copyLinkText(scope.row)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分享时间" width="150">
            <template slot-scope="scope">
              {{ scope.row.share_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="剩余时间(天)" width="150">
            <template slot-scope="scope">
              {{ residueDay(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="过期时间" width="150">
            <template slot-scope="scope">
              {{ scope.row.overdue_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column
            prop="click"
            label="点击次数"
            width="90"
          ></el-table-column>
          <el-table-column label="状态" width="90">
            <template slot-scope="scope">
              <template v-if="scope.row.isdel == 1">
                <el-switch
                  :value="true"
                  active-color="#cccccc"
                  inactive-color="#13ce66"
                  disabled
                >
                </el-switch>
              </template>
              <template v-else>
                <el-switch
                  v-model="scope.row.isdel"
                  active-color="#13ce66"
                  inactive-color="green"
                  @change="handleShareDisabled(scope.row)"
                >
                </el-switch
              ></template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import WaybillShare from 'api/waybill-share'
import { GetToken } from 'utils/auth'
import tableIndex from '@/mixins/table-index'
export default {
  components: {},
  name: '',
  mixins: [tableIndex],
  data() {
    return {
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      searchData: {
        waybillno: '', // 运单号
        agencyCode: '', // 代理商
        isdel: ['0'], // 状态
        createitme: '' // 获取日期
      },
      agencyChildren: [], // 子代理数据
      tableData: [],
      agencyData: [], // 主代理
      tableTotal: 0
    }
  },
  computed: {},
  watch: {},
  created() {
    this.searchData.agencyCode = this.$route.query.agency
    this.getList()
  },
  mounted() {},
  destroyed() {},
  methods: {
    residueDay(obj) {
      let _overdue = this.$dayjs(obj.overdue_time)
      let _now = this.$dayjs(new Date())
      let _diff = _overdue.diff(_now, 'day')
      if (_diff > 0) {
        return _diff + '天'
      } else {
        return ''
      }
    },
    // 分享
    copyLinkText(obj) {
      let url = `https://i.199.jp/waybill/share/details/${obj.code}`
      const input = document.createElement('input')
      document.body.appendChild(input)
      let _copyTxt = ''
      if (obj.auto_pwd) {
        _copyTxt = url + '?pwd=' + obj.pwd + ' 分享码:' + obj.pwd
      } else {
        _copyTxt = url + ' 分享码:' + obj.pwd
      }
      input.setAttribute('value', _copyTxt)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$message.success('已复制')
      }
      document.body.removeChild(input)
    },
    // 处理状态
    handleShareDisabled(obj) {
      console.log(obj, 'share disabled')

      WaybillShare.disableWaybillListAgency({
        token: GetToken(),
        code: obj.code,
        pwd: obj.pwd
      }).then((res) => {
        if (res.success) {
          this.$message.success('修改成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getList()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.searchData = {
        waybillno: '', // 运单号
        isdel: ['0'], // 状态
        createitme: '' // 获取日期
      }
      this.tableData = []
      this.getList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      this.getList()
    },
    getBaseParams() {
      let _start = '',
        _end = ''
      if (this.searchData.createitme) {
        _start = this.searchData.createitme[0]
        _end = this.searchData.createitme[1]
      }
      return {
        token: GetToken(),
        waybillno: this.searchData.waybillno,
        agency: this.searchData.agencyCode,
        begin: _start,
        isdel: this.searchData.isdel.length
          ? "'" + this.searchData.isdel.join("','") + "'"
          : '',
        end: _end
      }
    },
    getList() {
      WaybillShare.getWaybillAgencyShareList({
        ...this.getBaseParams(),
        curr: this.currentPage,
        pagesize: this.pageSize
      }).then((res) => {
        if (res.success) {
          this.tableData = res.record
          this.tableTotal = +res.message
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.delivery-total {
  background: #fff;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  span {
    color: red;
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin: 0 5px;
  }
}
.copy-container {
  display: flex;
  justify-content: space-between;
  .but-copy {
    margin-left: 10px;
    color: #4d1c8a;
    cursor: pointer;
  }
}
</style>
